<template>
  <v-row>
    <v-col
      v-for="(item, idx) in value"
      :key="item.id"
      class="col-12 col-lg-4 d-flex"
      :class="idx !== 2 ? 'col-sm-6' : ''"
    >
      <v-card elevation="8" class="d-flex flex-column text-center flex-grow-1">
        <v-card-title class="justify-center font-weight-bold text-h6">
          <EditorSimpleText
            v-if="isEditing"
            :value="item.title"
            @input="emitUpdate($event, idx, 'title')"
            bg="light"
          />
          <template v-else>
            <ReportContentOutput :value="item.title" />
          </template>
        </v-card-title>
        <div class="px-4">
          <EditorSimpleText
            v-if="isEditing"
            :value="item.text"
            @input="emitUpdate($event, idx, 'text')"
            bg="light"
          />
          <template v-else>
            <ReportContentOutput :value="item.text" />
          </template>
        </div>
      </v-card>
    </v-col>
  </v-row>

  <!-- <v-row>
    <v-col v-for="(item, idx) in value" :key="item.id">
      <EditorSimpleText :value="item.text" @input="emitUpdate($event, idx)"
    /></v-col>
  </v-row> -->
</template>
<script>
import EditorSimpleText from "@/components/editors/EditorSimpleText";
import ReportContentOutput from "@/components/ReportContentOutput.vue";

export default {
  props: {
    isEditing: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Array,
      required: false,
    },
  },
  components: {
    EditorSimpleText,
    ReportContentOutput,
  },
  methods: {
    emitUpdate(e, idx, prop) {
      let tempArray = [];

      tempArray = this.value;

      tempArray[idx][prop] = e;

      this.$emit(`update:value`, tempArray);
    },
  },
};
</script>
